@import 'styles/theme.scss';


.version-sidebar {
  position: fixed;
  right: 0;
  top: 64px;
  height: calc(100% - 64px);
  width: 270px;
  z-index: 5000;
  transition: 0.2s;

  &>* {
    user-select: none;
  }

  &.inactive {
    transform: translateX(100%);
  }

  &.active {
    transform: translateX(0);

    .sidebar-opener {
      display: none;
    }
  }

  .sidebar-opener {
    background-color: #fff;
    position: absolute;
    top: 10px;
    left: 1px;
    transform: translateX(-100%) rotate(180deg);
    border-radius: 0 6px 6px 0;
    box-shadow: -5px 0 10px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding: 14px 0 8px 14px;
  }

  .group {
    margin-bottom: 20px;
  }

  .version-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-left: 1px solid #E2E3E8;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .version-header {
    padding: 15px 0 20px 20px;

    .header-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h1 {
      font-size: 16px;
      color: #05034D;
      font-weight: 600;
      margin: 0;
    }

    .subtitle {
      font-size: 12px;
      opacity: 0.8;
    }

    .anticon {
      transition: 0.4s;
      padding: 4px;
      border-radius: 30px;
      cursor: pointer;
    }
  }

  .lists {
    @include custom-scrollbar;
    flex: 1;
    overflow-y: auto;
    padding-bottom: 20px;
  }


  h2 {
    font-size: 13px;
    color: transparent;
    position: relative;
    height: 20px;
    padding: 0 20px;
    color: #4F4E7B;
    font-weight: 600;

    &::before {
      content: attr(title);
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0 20px;
      height: 100%;
      color: #4F4E7B;
      background-color: #fff;
      z-index: 2;
      padding-right: 10px;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 5px;
      width: 100%;
      right: 0;
      height: 1px;
      background-color: #E2E3E8;
      z-index: 1;
    }
  }

  .version-skeleton {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px 20px;

    .ant-skeleton:nth-child(2) .ant-skeleton-input {
      height: 20px;
    }
  }

  .version-item {
    padding: 10px 20px;
    cursor: pointer;
    color: #05034D;
    position: relative;

    &:hover {
      background-color: #F5F6FA;
    }

    .time {
      display: flex;
      gap: 10px;
      align-items: center;
      position: relative;
    }

    .editor {
      font-size: 14px;
      color: #4F4E7B;
    }

    &:after {
      color: #fff;
      font-size: 11px;
      border-radius: 10px;
    }

    &.active {
      background-color: $secondary-color-light;
    }

    &.live {
      &:after {
        position: absolute;
        right: 12px;
        top: calc(50% - 10px);
        content: 'CURRENT';
        background-color: $secondary-color;
        padding: 2px 8px 2px;
      }
    }
  }

  .circle-loading {
    position: absolute;
    right: 17px;
  }

  .ant-empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .sidebar-opener {
    top: -55px;
  }
}

.history-preview {
  &.version-sidebar-inactive .publish-content {
    margin-right: 0px !important;
  }
}